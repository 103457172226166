.breadcrumbs {
  display: flex;
  flex-direction: row;
  div {
    a {
      margin-right: 20px;
      position: relative;
      font-size: 13px;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
      transition: all 0.4s ease;
      &:hover {
        color: rgb(0, 0, 0);
      }
      &::after {
        content: "/";
        position: absolute;
        right: -12px;
        top: -3px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &:last-child {
      a {
        &::after {
          content: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .breadcrumbs {
    flex-wrap: wrap;
    a {
      min-width: fit-content;
    }
  }
}