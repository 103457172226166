.header {
  padding: 20px 0 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 999999;
  &.fixed {
    padding: 10px 0 0;
    .divider {
      margin-top: 10px;
    }
  }
  .socials_pc {
    display: flex;
    margin-right: 24px;
    a {
      color: rgba(0, 0, 0, 0.3);
      font-size: 24px;
      transition: all 0.3s ease;
      &:nth-child(1) {
        margin-right: 16px;
      }
      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .account_btns {
    display: flex;
    button,
    a {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      border-radius: 8px;
      outline: none;
      border: none;
      color: rgba(0, 0, 0, 0.7);
      background-color: transparent;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      transition: all 0.3s ease;
      &:hover {
        color: rgb(0, 0, 0);
      }
      &:last-child {
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.9);
        background-color: #f7ce30;
        &:hover {
          color: rgba(0, 0, 0, 0.6);
          background-color: #f3c511;
        }
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin-top: 20px;
    transition: all 0.3s ease;
  }
  .logo {
    font-size: 42px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    text-decoration: none;
    outline: none;
    font-family: 'Farm';
    padding-top: 5px;
    display: block;
    img {
      width: 250px;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    a {
      color: rgb(0, 0, 0);
      text-decoration: none;
      margin-right: 42px;
      font-size: 16px;
      font-weight: 500;
      opacity: 0.7;
      transition: all 0.4s ease;
      white-space: nowrap;
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        position: relative;
        // &::before {
        //   content: " ";
        //   position: absolute;
        //   bottom: 0px;
        //   width: 100%;
        //   left: 0%;
        //   background-color: rgba(255, 255, 255, 0.1);
        //   height: 1px;
        //   transition: all 0.1s ease;
        //   animation: menu_active 3s ease infinite;
        // }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.ctry {
  position: absolute;
  top: 18px;
  display: flex;
  right: -10px;
  width: 100px;
  // background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 5px 0px;
  cursor: pointer;
  transition: all 0.3s ease;
  div {
    opacity: 0.5;
    transition: all 0.3s ease;
    &:nth-child(1) {
      font-weight: 600;
      margin-right: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Farm';
      white-space: pre;
    }
    &:nth-child(2) {
      opacity: 0.3;
    }
  }
  &:hover {
    // background-color: rgba(0, 0, 0, 0.07);
    div {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 768px) {
  .ctry {
    right: -130px !important;
  }
}

@keyframes menu_active {
  0% {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    width: 80%;
    left: 10%;
    background-color: rgba(255, 255, 255, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.flexed_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.xbutton {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background-color: #3F634C;
  border: none;
  outline: none;
  border-radius: 10px;
  transition: all 0.4s ease;
  text-align: center;
  text-decoration: none;
  position: relative;
  strong {
    font-weight: 500;
  }
  &:hover {
    background-color: #456c53;
    color: #fff;
  }
  &.iconed {
    svg {
      margin-right: 10px;
    }
  }
  &.has_active {
    strong {
      padding-right: 20px;
    }
  }
  span {
    position: absolute;
    top: 14px;
    right: 20px;
    background-color: #ffffff33;
    color: #fff;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Farm";
    font-weight: 700;
    font-size: 13px;
    line-height: 1;
    padding-top: 2px;
  }
}

.xfield {
  padding: 12px 24px;
  background-color: #3F634C;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.4s ease;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background-color: #456c53;
  }
  span {
    margin-right: 10px;
    color: #fff;
    &.search {
      cursor: pointer;
      position: relative;
      right: -10px;
      transition: all 0.2s ease;
      // font-size: 24px;
    }
  }
  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .xbutton {}
}

.toolbar {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .xbutton {
    width: fit-content;
    b {
      font-weight: 500;
    }
    &:nth-child(1) {
      min-width: 160px;
      margin-right: 16px;
    }
  }
  .actions {
    .xbutton {
      &:nth-child(1) {
        min-width: 260px;
        margin-right: 16px;
        margin-left: 16px;
      }
      &:nth-child(2) {
        margin-right: 16px;
      }
      &:nth-child(3) {
        margin-right: 16px;
      }
      &:nth-child(4) {}
    }
  }
  .xfield {
    width: 100%;
    min-width: 500px;
  }
}

.ended_wrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.city_wrap {
  position: relative;
  .city_modal {
    position: absolute;
    width: 320px;
    background-color: #fff;
    border-radius: 12px;
    right: 0;
    top: 50px;
    padding: 16px;
    z-index: 9999999;
    transition: all 0.3s ease;
    &::before {
      content: " ";
      position: absolute;
      top: -6px;
      right: 16px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background-color: #fff;
    }
    .city_modal__title {
      font-size: 32px;
      font-weight: 900;
      line-height: 1;
      font-family: 'Farm';
    }
    .city_modal__text {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 500;
    }
    .city_modal__action {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        border: none;
        outline: none;
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
        padding: 14px 14px 12px;
        border-radius: 10px;
        background-color: transparent;
        color: #000;
        cursor: pointer;
        font-family: 'Farm';
        transition: all 0.3s ease;
        &:hover {
          background-color: rgb(238, 238, 238);
        }
        &.primary {
          background-color: rgb(0 206 2);
          color: #fff;
          margin-right: 12px;
          &:hover {
            background-color: rgb(0, 181, 3);
          }
        }
      }
    }
  }
}

.city_select {
  font-size: 24px;
  color: #fff;
  background: none;
  border: none;
  outline: none;
  font-family: 'Farm';
  font-weight: 700;
  // white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  // max-width: 136.5px;
  b {
    margin-left: 2px;
    font-size: 14px;
    position: relative;
    top: -4px;
  }
}

.menu_btn {
  font-size: 20px;
  color: #000;
  background: none;
  border: none;
  outline: none;
  margin: 0 !important;
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.menu_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(6px);
  transition: all 0.3s ease;
  z-index: 9999999999999;
  .logo {
    padding: 10px 12px;
    a {
      outline: none;
      text-decoration: none;
      font-size: 42px;
      font-weight: 700;
      color: rgb(255, 255, 255);
      text-decoration: none;
      outline: none;
      font-family: 'Farm';
      padding-top: 5px;
      display: block;
      img {
        width: 200px;
      }
    }
  }
  .close {
    position: absolute;
    top: 25px;
    right: 12px;
    color: #fff;
    font-size: 24px;
    width: 32px;
    height: 32px;
    text-align: center;
  }
  .menu_items {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-top: 30px;
    .menu_items__item {
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      margin-bottom: 20px;
      &.active {
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .socials {
    margin-top: 40px !important;
    // margin-bottom: 0px !important;
    a {
      background-color: transparent !important;
      font-size: 32px !important;
      padding: 0px !important;
      margin-right: 24px !important;
    }
  }
  .actions,
  .socials {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 0 0 0 20px;
    margin-top: 30px;
    a {
      padding: 12px 24px;
      margin-right: 16px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      outline: none;
      border-radius: 10px;
      transition: all 0.4s ease;
      text-align: center;
      text-decoration: none;
      &:hover {
        background-color: rgb(48, 48, 48);
        color: #fff;
      }
      &.iconed {
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .city_select {
    strong {
      width: 100px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: end;
      margin-right: 4px;
    }
    b {
      position: relative;
      top: 0px;
      width: 12.3px;
    }
  }
  .header {
    .logo {
      img {
        width: 170px;
      }
    }
    .toolbar {
      .xbutton {
        min-width: unset;
        padding: 12px 16px !important;
        font-size: 16px !important;
        b {
          display: none;
        }
        &.icon2 {
          svg {
            margin-right: 0px !important;
          }
        }
      }
      .xfield {
        min-width: unset !important;
        width: 100%;
        margin-right: 16px;
        padding: 9.5px 24px;
      }
      .actions {
        .xbutton {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {}
          &:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {}

@media (min-width: 540px) and (max-width: 768px) {}

@media screen and (min-width: 768px) {
  .header {
    .logo {
      img {
        width: 200px;
      }
    }
    .toolbar {
      .xbutton {
        min-width: unset;
        padding: 12px 16px !important;
        font-size: 16px !important;
        b {
          display: none;
        }
        &.icon2 {
          svg {
            margin-right: 0px !important;
          }
        }
      }
      .xfield {
        min-width: unset !important;
        width: 100%;
      }
      .actions {
        .xbutton {
          &:nth-child(1) {
            min-width: 200px !important;
          }
          &:nth-child(2) {}
          &:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .city_select {
    margin-right: 20px;
    padding-right: 0px !important;
  }
  .header {
    padding-top: 10px !important;
    .divider {
      margin-top: 10px !important;
    }
    &.fixed {
      padding-top: 0px !important;
      .divider {
        margin-top: 0px !important;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .city_select {
    // font-size: 24px !important;
    b {
      // font-size: 17px !important;
    }
  }
  .header {
    .logo {
      img {
        width: 200px;
      }
    }
    .toolbar {
      .xbutton {
        min-width: unset;
        padding: 12px 16px !important;
        font-size: 16px !important;
        &:nth-child(1) {
          // margin-right: 8px;
        }
        &:nth-child(2) {
          // margin-right: 8px;
        }
        b {
          display: none;
        }
        &.icon2 {
          svg {
            margin-right: 0px !important;
          }
        }
      }
      .xfield {
        min-width: unset;
        padding: 10px 24px;
        font-size: 14px;
        // margin-right: 8px;
      }
      .actions {
        .xbutton {
          &:nth-child(1) {
            min-width: 200px;
          }
          &:nth-child(2) {
            // display: none;
          }
          &:nth-child(3) {
            // display: none;
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .header {
    .logo {
      img {
        width: 250px !important;
      }
    }
    .toolbar {
      .xbutton {
        min-width: fit-content !important;
        &.icon2 {
          b {
            display: inline-block !important;
          }
          svg {
            margin-right: 8px !important;
          }
        }
      }
      .xfield {
        width: 100%;
      }
      .actions {
        .xbutton {
          &:nth-child(1) {
            min-width: 220px;
          }
          &:nth-child(2) {}
          &:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
    }
  }
}