.cities {
  padding-top: 140px;
  .wrapper {
    background-color: #fff;
    // padding: 16px 16px;
    border-radius: 10px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 36px;
    font-weight: 900;
  }
  .cities_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 42px;
    padding-bottom: 42px;
    div {
      width: 25%;
      margin-bottom: 16px;
      a {
        font-size: 20px;
        color: rgb(0, 0, 0);
        opacity: 0.7;
        transition: all 0.3s ease;
        text-decoration: none;
        outline: none;
        position: relative;
        &:hover {
          opacity: 1;
          &::after {
            opacity: 1;
            bottom: -2px;
          }
        }
        &::after {
          content: " ";
          width: 100%;
          position: absolute;
          height: 1px;
          bottom: -5px;
          opacity: 0;
          left: 0;
          background-color: rgb(0, 0, 0);
          transition: all 0.3s ease;
          animation: menu_active 2s ease infinite;
        }
      }
    }
  }
}

@keyframes menu_active {
  0% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    width: 80%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 768px) {
  .cities_list {
    div {
      width: 50% !important;
      margin-bottom: 14px !important;
      a {
        font-size: 17px !important;
      }
    }
  }
}