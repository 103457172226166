.footer_wrap {
  padding: 42px 0 22px;
  .fw_top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .fw_top_logo {
      width: 50%;
      .alogo {
        font-size: 60px;
        font-weight: 700;
        color: rgb(0, 0, 0);
        text-decoration: none;
        outline: none;
        font-family: 'Farm';
        line-height: 1;
      }
      img {
        width: 300px;
      }
      .socials {
        margin-top: 16px;
        a {
          display: inline-block;
          margin-right: 30px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.5);
          text-decoration: none;
          outline: none;
          transition: all 0.3s ease;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .contacts {
        margin-top: 24px;
        a {
          font-family: 'Farm';
          font-size: 30px;
          font-weight: 700;
          text-decoration: none;
          display: block;
          transition: all 0.3s ease;
          color: rgba(0, 0, 0, 0.7);
          width: fit-content;
          min-width: fit-content;
          &:hover {
            color: rgba(0, 0, 0, 0.98);
          }
        }
      }
    }
    .fw_top_menus {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: end;
      width: 100%;
      .fw_top_menu {
        margin-right: 200px;
        &:last-child {
          margin-right: 0px;
        }
        h4 {
          font-family: 'Farm';
          font-size: 30px;
          font-weight: 700;
          color: rgb(0, 0, 0);
        }
        a {
          display: block;
          text-decoration: none;
          color: rgb(0, 0, 0);
          font-size: 18px;
          margin-bottom: 10px;
          opacity: 0.7;
          transition: all 0.3s ease;
          position: relative;
          width: fit-content;
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            opacity: 1;
            &::after {
              opacity: 1;
              bottom: 0px;
            }
          }
          &::after {
            content: " ";
            width: 100%;
            position: absolute;
            height: 1px;
            bottom: -4px;
            opacity: 0;
            left: 0;
            background-color: rgb(0, 0, 0);
            transition: all 0.3s ease;
            animation: menu_active 2s ease infinite;
          }
        }
      }
    }
  }
  .fw_bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .fw_bottom__copys {
      color: rgb(0, 0, 0);
      opacity: 0.7;
      // font-family: 'Farm';
      font-size: 15px;
      // font-weight: 700;
    }
    .fw_bottom__links {
      a {
        color: rgb(0, 0, 0);
        opacity: 0.7;
        transition: all 0.3s ease;
        text-decoration: none;
        position: relative;
        font-size: 15px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          opacity: 1;
          &::after {
            opacity: 1;
            bottom: -2px;
          }
        }
        &::after {
          content: " ";
          width: 100%;
          position: absolute;
          height: 1px;
          bottom: -5px;
          opacity: 0;
          left: 0;
          background-color: rgb(0, 0, 0);
          transition: all 0.3s ease;
          animation: menu_active 2s ease infinite;
        }
      }
    }
  }
}

@keyframes menu_active {
  0% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    width: 80%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.to_top {
  color: #d2a367;
  background-color: #1f201f;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  font-size: 26px;
  padding-top: 6px;
  transition: all 0.3s ease;
  z-index: 9999999;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 960px) {}

@media screen and (max-width: 960px) {}

@media screen and (max-width: 768px) {
  .footer_wrap {
    padding: 10px 10px 38px;
    .fw_top {
      flex-direction: column;
      .fw_top_logo {
        width: 100%;
        img {
          width: 260px;
        }
        .contacts {
          a {
            font-size: 24px !important;
          }
        }
      }
      .fw_top_menus {
        justify-content: unset;
        margin-top: 24px;
        .fw_top_menu {
          width: 100%;
          margin-right: 0px !important;
          h4 {
            font-size: 20px !important;
          }
          a {
            font-size: 16px !important;
          }
        }
      }
    }
    .fw_bottom {
      flex-direction: column;
      align-items: flex-start;
      .fw_bottom__copys {
        margin-bottom: 6px;
      }
      .fw_bottom__links {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: -16px;
        a {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 1600px) {}

@media screen and (min-width: 1920px) {}

@media screen and (min-height: 1250px) {}