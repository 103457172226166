.card_wrap {
  // background-color: #FBFBDB;
  // padding: 16px 16px;
  border-radius: 10px;
  margin-bottom: 32px;
  margin-top: 125px;
  min-height: 590px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .card_wrap {
    margin-top: 105px !important;
  }
}