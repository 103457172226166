body {
  margin: 0;
  font-family: "TildaSans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  transition: background-color 0.3s ease;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

html {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

* {
  scroll-behavior: smooth;
}

.stars_min {
  font-size: 13px;
  .ant-rate-star {
    margin-right: 3px !important;
  }
}

.xrate_studio_card {
  .ant-rate-star {
    margin-right: 4px !important;
  }
}

.ant-steps-item-icon {
  // background-color: #f7ce30 !important;
  // color: #000 !important;
}

.ant-steps-item-title,
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.88) !important;
  font-size: 18px !important;
  letter-spacing: 0.6px;
  line-height: 1.4;
  font-weight: 500;
  font-family: 'TildaSans' !important;
}

.ant-steps-item-description {
  max-width: 170px !important;
  // font-size: 16px !important;
  position: relative !important;
  top: -5px !important;
  left: 1px;
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    border-color: #f7ce30 !important;
    background-color: #f7ce30 !important;
    span {
      color: #000 !important;
    }
  }
}

#root {
  height: 100vh;
  height: 100svh;
  height: 100dvh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
}

@media screen and (max-width: 1024px) {
  #root {
    height: 100vh;
    height: 100dvh;
  }
}

.mt-6 {
  margin-top: 60px !important;
}

.win-upscale {
  h1,
  span:not(.nomore),
  a,
  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  .langsx>div {
    transform: translateY(-4px);
    line-height: 1;
    &::before {
      bottom: -4px !important;
    }
  }
}

.rsis-container {
  div {
    transition-timing-function: cubic-bezier(1, -0.03, 0.65, 0.9) !important;
    background-position: center !important;
  }
}

@media screen and (max-width: 1328px) {
  .global_wrap {
    // margin-bottom: 55px !important;
  }
}

@media (min-width: 600px) and (max-width: 1430px) {
  .row {
    // padding-left: 30px;
    // padding-right: 30px;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

* {
  scroll-behavior: smooth;
}

 ::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

 ::-webkit-scrollbar-thumb {
  background-color: #babac07c;
  border-radius: 16px;
}

 ::-webkit-scrollbar-track:hover {}

 ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

 ::-webkit-scrollbar {
  width: 1px;
  max-height: 1px;
  transition: all 0.4s ease;
  &:focus,
  &:focus-visible,
  &:hover,
  &:active {
    width: 10px !important;
    max-height: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .win-upscale {}
  .marked {
    position: relative;
    left: -9px;
    width: fit-content;
  }
}

.ant-float-btn-circle {
  width: 52px !important;
  height: 52px !important;
}

.ant-float-btn-group {
  width: 52px !important;
  z-index: 99999999999 !important;
}

.ant-float-btn-body {
  width: 52px !important;
  height: 52px !important;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: #ffffff80 !important;
  backdrop-filter: blur(10px) !important;
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: #008090 !important;
}

.ant-float-btn-icon {
  width: 24px !important;
  font-size: 24px !important;
}

.fader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0px;
  left: 0px;
  background-color: #a2a2a2;
  transition: all 0.6s ease;
  z-index: 9999999999999;
  div {
    height: 100vh;
    height: 100svh;
    justify-content: center;
    display: flex;
    img {
      z-index: 9999;
      width: 300px;
      height: 100%;
      transition: all 1s ease;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  align-items: center;
}

.loading {
  text-align: center;
  padding: 240px 0 0;
}

.swiper {
  // padding-bottom: 90px;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.swiper-pagination-bullet {
  background: #000 !important;
  transition: all 0.4s ease;
}

.swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 0.5) !important;
  // border: 2px solid rgba(0, 0, 0, 0.5) !important;
  padding: 5px !important;
}

.modal_text {
  font-size: 16px;
  color: #000;
  padding-bottom: 4px;
}

.modal_text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.modal_text p {
  margin-top: -8px;
  text-align: left;
}

.modal_action {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  z-index: 999999999;
  background-color: #fff;
  padding: 0px 16px 0px 16px;
  &.bottomed {
    position: fixed;
    bottom: 0;
    z-index: 9999999;
  }
  &.modal_actionx {
    padding: 0px;
    align-items: flex-start;
    margin-top: 0px;
  }
}

.tox-menu {
  z-index: 99999999999 !important;
}

.tox {
  z-index: 99 !important;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal_action button {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.6px;
  font-size: 16px;
  background: rgb(0, 177, 3);
  outline: none !important;
  border: none !important;
  // text-transform: uppercase;
  border-radius: 100px;
  margin-bottom: 10px;
  padding: 14px 34px 12px;
  line-height: 1;
  &:disabled {
    opacity: 0.6 !important;
    user-select: none !important;
    pointer-events: none !important;
  }
  &.danger {
    background-color: rgb(198, 13, 13);
    color: rgb(255, 255, 255);
    opacity: 0.6;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }
}

.modal_action a {
  color: #000000;
  font-weight: 500;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

.modal_action button:first-child {}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_form {
  div {
    width: 100%;
    margin-bottom: 16px;
    label {
      font-size: 18px;
      margin-bottom: 6px;
      small {
        color: red;
      }
    }
    input,
    textarea {
      width: 100%;
      border: 3px solid #C6B99D;
      color: rgb(0, 0, 0);
      padding: 8px 12px;
      background-color: transparent;
      border-radius: 0px;
      outline: none;
      font-size: 18px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.awssld__container {
  padding-bottom: 57% !important;
}

.awssld__controls button {
  width: 4% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background: #fff !important;
}

.awssld__bullets {
  bottom: 30px !important;
  z-index: 999999999 !important;
}

.awssld__bullets .awssld__bullets--active {
  background: #fff !important;
}

.awssld__bullets button {
  background: #ffffff4a !important;
}

.padded_content {
  padding-top: 120px;
  padding-bottom: 42px;
}

.xbtn_sl {
  opacity: 0.7;
  &:hover {
    opacity: 1;
    // width: 50px !important;
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  &.light {
    margin-bottom: 32px !important;
    .breadcrumbs_item {
      color: rgba(255, 255, 255, 0.5) !important;
      &:last-child {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  .breadcrumbs_item {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 4px;
    &:first-child {
      margin-left: 4px;
      cursor: pointer !important;
    }
    &:last-child {
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.page_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #242424;
  &.light {
    color: #fff;
  }
}

.page_subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

.page_desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .awssld__container {
    padding-bottom: 160% !important;
  }
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}

@media screen and (min-width: 320px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 960px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px !important;
  }
}

@media screen and (min-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1420px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 0px !important;
  .ant-collapse-header-text {
    //color: #fff !important;
    font-size: 22px !important;
    font-weight: 500;
  }
  .ant-collapse-expand-icon {
    //color: #fff !important;
    height: 31px !important;
    span {
      font-size: 18px !important;
      svg {}
    }
  }
  .ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;
    //color: #fff !important;
    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      opacity: 0.8;
      p {
        //color: #fff !important;
      }
    }
  }
  .ant-collapse-header {
    //flex-direction: row-reverse !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .ant-collapse-header-text {
      font-size: 20px !important;
    }
  }
  .ant-collapse-item {
    border-radius: 0px !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
}

.headSlider {
  width: 100% !important;
  position: absolute;
  img {
    width: 100%;
  }
  .hs_sl {
    height: 630px;
    width: 100%;
    div {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 630px;
    }
  }
}

.foodSlider {
  width: 101.3% !important;
  img {
    width: 90%;
  }
}

.arhbtn {
  margin-top: 32px;
  background-color: #C8B79D;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 42px;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  &.arhbtn-primary {
    background-color: #dfa25f;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }
  &.arhbtn-round {
    background-color: #dfa25f;
    color: #fff;
    border-radius: 100px;
    width: 100%;
  }
}

.btnb {
  background-color: #ffc064 !important;
  color: #1f1d1d;
  padding: 11px 20px;
  font-size: 22px;
  font-weight: 300;
  border: none;
  outline: none;
  display: inline-block;
  width: fit-content;
  text-decoration: none;
  font-family: "Yanone Kaffeesatz", Arial, Tahoma, sans-serif !important;
  margin-bottom: 16px;
  &:hover {
    color: #1f1d1d !important;
  }
}

table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  tr {
    &:first-child {
      td {
        background: none;
        border-width: 0 1px 1px 0;
        box-shadow: inset 0px 4px 3px -2px rgba(0, 0, 0, .06);
      }
    }
    td {
      padding: 10px;
      text-align: left;
      border-width: 1px;
      border-style: solid;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 300;
      border-color: rgba(0, 0, 0, .08);
      font-family: "Yanone Kaffeesatz", Arial, Tahoma, sans-serif !important;
      strong {
        font-weight: 700;
      }
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.ant-menu {
  background: transparent !important;
  border-inline-end: none !important;
  .ant-menu-submenu-title {
    background-color: transparent;
    color: #fff !important;
    padding-left: 0px !important;
    font-weight: 700 !important;
    &:active,
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  .ant-menu-item-selected {
    background: transparent !important;
  }
  .ant-menu-item {
    padding-left: 0px !important;
    span {
      color: #fff !important;
      font-weight: 500 !important;
    }
  }
}

.ant-upload-select {
  display: block !important;
}

.ant-message {
  z-index: 999999999999 !important;
}

#rsisi {
  div:not(.rsis-container) {
    overflow: unset !important;
  }
  .rsis-container {
    overflow: hidden !important;
  }
}

.global_wrap {
  padding-top: 72px;
  margin-bottom: 110px;
  overflow: hidden;
}

.contact-info-block {
  .contacts-grid {
    padding-top: 0;
    display: flex;
    transition: all 0.4s ease;
    .contact-info {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 45px;
      display: flex;
      a {
        color: #000;
        padding-bottom: 4px;
        font-family: "TTFors", sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.4s ease;
        bottom: 0px;
      }
    }
  }
}

.contacts-links {
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: static;
  a {
    transition: all 0.4s ease;
  }
}

.contacts-link-info-right {
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-right: 40px;
  font-family: "TTFors", sans-serif;
  font-size: 11px;
  text-transform: capitalize;
  text-decoration: none;
  transition: border-width .2s;
  &:hover {
    color: #000;
  }
}

@media screen and (max-width: 960px) {
  .global_wrap {
    // padding-top: 80px;
    // margin-bottom: 80px;
  }
  .contacts-grid {
    flex-direction: column;
    .contact-info {
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .global_wrap {
    padding-top: 61px;
    margin-bottom: 90px;
  }
  .fader {
    div {
      img {
        width: 160px !important;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .contact-info-block {
    padding-left: 18px;
  }
  .info-contacts {
    padding-bottom: 20px !important;
    font-size: 15px !important;
    line-height: 1;
  }
  .contact-info {
    margin-right: 80px !important;
  }
  .contacts-link-info-right {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 1600px) {
  .global_wrap {
    // padding-top: 78px;
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1920px) {
  .global_wrap {
    // padding-top: 80px;
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 960px) {
  .hrefed {
    position: relative;
    &.grey {
      &::before {
        background-color: #969696 !important;
      }
    }
    &::before {
      content: " ";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0px;
      height: 1px;
      background-color: #fff;
      transition: all 0.3s ease;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
  .hrefeds {
    span {
      width: fit-content;
      position: relative;
      &.grey {
        &::before {
          background-color: #969696 !important;
        }
      }
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: #fff;
        transition: all 0.3s ease;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

.tox .tox-statusbar__help-text {
  font-size: 7px !important;
  line-height: 2.2 !important;
}

@media screen and (max-width: 768px) {
  .swiper {
    overflow: unset;
    .swiper-pagination {
      height: 14px !important;
      bottom: -20px !important;
      align-items: center;
      display: flex;
      margin: 0 auto;
      width: fit-content;
      position: relative;
    }
  }
}

@media screen and (min-width: 769px) {
  .swiper-pagination {
    display: none !important;
  }
}

.ant-picker-dropdown {
  z-index: 99999991050 !important;
  .ant-picker-content {
    td {
      font-size: 14px !important;
      text-align: center !important;
    }
  }
  table {
    margin-bottom: 0px !important;
  }
  .ant-picker-body {
    padding: 0px !important;
  }
  .ant-picker-cell {
    padding: 3px 0 !important;
  }
  .ant-picker-cell-inner {
    // min-width: 28px !important;
    // height: 28px !important;
    // line-height: 28px !important;
  }
}

.ant-picker {
  padding: 11px 12px !important;
  border-radius: 8px !important;
  .ant-picker-input {
    input {
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.cities_selecting {
  .cs_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.6;
    &.selected {
      opacity: 1;
      .cs_item__check {
        span {
          display: block !important;
        }
      }
    }
    .cs_item__name {
      font-size: 20px;
      font-weight: 600;
      width: 100%;
    }
    .cs_item__check {
      font-size: 20px;
      color: #01B005;
      span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cities_selecting {
    .cs_item {
      height: 30px;
      .cs_item__name {
        font-size: 17px !important;
      }
    }
  }
}

.xfader {
  transition: all 0.4s ease;
}

.loadingx {
  text-align: center;
  color: #fff;
  font-size: 120px;
  svg {}
}

.adminx_modal_content {
  padding: 0 60px 0 42px;
  .modalx_form {
    .flexed {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .modalx_form__item {
        width: 100%;
        margin-right: 16px;
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
    .modalx_form__item {
      margin-bottom: 20px;
      label {
        color: #000;
        display: block;
        // font-family: "RobotoCondensed";
        margin-bottom: 3px;
        small {
          color: red;
        }
      }
      input {
        width: 100%;
        border: none;
        color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px 12px;
        outline: none;
        font-size: 18px;
        // font-family: "RobotoCondensed";
        &::placeholder {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}

.adminx_modal_action {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 999999999;
  padding: 20px 32px 12px;
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  div {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    button {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgb(0, 0, 0);
      border-radius: 10px;
      padding: 8px 24px 8px;
      outline: none !important;
      border: none !important;
      font-size: 18px;
      letter-spacing: 1.4px;
      margin-right: 16px;
      margin-bottom: 16px;
      &.danger {
        background-color: rgb(198, 13, 13);
        color: rgb(255, 255, 255);
        opacity: 0.6;
        transition: all 0.3s ease;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .adminx_modal_content {
    padding: 0 20px !important;
  }
  .adminx_modal_action {
    padding: 20px 20px 32px;
  }
  .modalx_form {
    .flexed {
      flex-direction: column !important;
    }
  }
}

.color_pick {
  margin-top: 16px !important;
  width: 100% !important;
}

.selectx {
  width: 100%;
  border: none;
  color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 12px;
  outline: none;
  font-size: 18px;
  // font-family: "RobotoCondensed";
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;
  select {
    border: none;
    background-color: transparent;
    appearance: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 9999999;
    option {}
  }
  .bbb {
    display: block;
    position: absolute;
    right: 16px;
    opacity: 0.6;
    top: 15px;
  }
  span {
    display: block;
    position: absolute;
    right: 16px;
    opacity: 0.6;
  }
}

.xfield {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  label {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  input,
  textarea,
  select {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.06);
    outline: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 8px;
    width: 100%;
  }
}

.xform {
  .xfield {
    margin-bottom: 12px;
  }
}

figure {
  float: none !important;
}

.xflexed {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .xfield {
    &:not(:first-child) {
      margin-left: 12px;
    }
    &.sm {
      width: 48%;
    }
  }
}

@media screen and (max-width: 768px) {
  .xfield {
    input,
    textarea {
      font-size: 14px !important;
    }
  }
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #00ce02 !important;
}

.ant-switch.ant-switch-checked {
  background: #00ce02;
}

.ant-checkbox {
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #f7c400 !important;
      border-color: #cda200 !important;
    }
  }
  &:hover {
    .ant-checkbox-inner {
      background-color: #ffdf5d !important;
      border-color: #cda200 !important;
    }
  }
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    &::after {
      inset-inline-start: 31% !important;
      border: 2px solid #000000 !important;
      border-top: 0 !important;
      border-inline-start: 0 !important;
    }
  }
}

.table-responsive {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0px 0px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.06);
  &.whited {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background-color: rgb(255 255 255) !important;
  }
  .table-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .table-bottom__item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-right: 0px;
      }
      .tbi__ttl {
        font-size: 13px;
        letter-spacing: 1.3px;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 12px;
      }
    }
  }
  .table-actions {
    span {
      margin-right: 16px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .table {
    margin-bottom: 0px !important;
    // min-height: 340px;
    &.isloading {
      min-height: unset !important;
    }
    &.light {
      th,
      td {
        color: rgba(0, 0, 0, 0.5) !important;
        &::before {
          background-color: rgba(0, 0, 0, 0) !important;
        }
        &:hover {
          color: rgba(0, 0, 0, 1) !important;
        }
        .ordered {
          color: rgb(0 0 0 / 24%);
          position: relative;
          top: -3px;
          font-size: 14px;
          left: 2px;
        }
      }
      tr {
        // border-bottom: 1.5px solid #0000000f;
        td {
          color: rgba(0, 0, 0, 0.6) !important;
          &::before {
            background-color: rgba(0, 0, 0, 0.1) !important;
          }
        }
        &:hover {
          td {
            color: #000 !important;
          }
        }
      }
      tbody {
        border-top: 1.5px solid rgba(0, 0, 0, 0.1);
      }
    }
    th,
    td {
      color: #fff;
      font-weight: 500;
      transition: all 0.3s ease;
    }
    thead {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      tr {
        margin-bottom: 8px;
      }
    }
    th {
      border-bottom: none !important;
      padding-left: 16px;
      position: relative;
      font-size: 14px;
      &:first-child {
        border-left: none !important;
        &:before {
          display: none;
        }
      }
      &:before {
        background-color: rgba(255, 255, 255, 0.3);
        width: 1px;
        height: 21px;
        content: " ";
        position: absolute;
        left: 0px;
      }
    }
    tr {
      cursor: pointer;
      &:last-child {
        td {
          border-bottom: none !important;
        }
      }
      td {
        position: relative;
        color: #fff;
        padding-left: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        &:first-child {
          border-left: none !important;
          &:before {
            display: none;
          }
        }
        &:before {
          background-color: rgba(255, 255, 255, 0.3);
          width: 1px;
          height: 21px;
          content: " ";
          position: absolute;
          left: 0px;
        }
      }
      &:hover {
        td {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

.ant-pagination .ant-pagination-item a {
  color: #000000a8 !important;
}

.ant-pagination-item-link {
  color: #fff !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #000 !important;
}

.ant-pagination {
  &.lighted {
    .ant-pagination-item-link {
      color: #000 !important;
      position: relative;
      top: -4px;
      &:disabled {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}

.ant-select-dropdown {
  z-index: 999999999999;
}

.xbutton {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.5);
  padding: 12px 42px;
  text-align: center;
  font-family: "Farm";
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  &:hover {
    color: #000;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.placeholder {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #8E8D8B;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  .ph_wrap {
    text-align: center;
    width: fit-content;
    height: fit-content;
    img {
      width: 360px;
    }
    p {
      color: #fff;
      font-size: 24px;
      max-width: 400px;
      margin: 30px auto 60px;
      a {
        display: block;
        margin-top: 20px;
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
        font-size: 16px;
        position: relative;
        width: fit-content;
        margin: 20px auto 0;
        transition: all 0.4s ease;
        &::before {
          content: " ";
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 0px;
          height: 1px;
          background-color: #fff;
          transition: all 0.4s ease;
        }
        &:hover {
          color: #fff;
          &::before {
            width: 100%;
          }
        }
      }
    }
    .yt {
      overflow: hidden;
      width: 550px;
      height: 350px;
      border-radius: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .placeholder {
    .ph_wrap {
      img {
        width: 250px;
      }
      p {
        font-size: 18px;
        max-width: 290px;
      }
      a {
        &::before {
          display: none;
        }
      }
      .yt {
        width: 330px;
        height: 220px;
      }
    }
  }
}

.ant-modal-mask {
  z-index: 999991000 !important;
}

.ant-modal-wrap {
  z-index: 999991000 !important;
}

.ant-upload-list {
  display: flex;
}

.main_wrapper {
  padding-top: 120px;
}

.ant-carousel .slick-next {
  right: -30px;
}

.ant-carousel .slick-prev {
  left: -30px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-prev {
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  &::after {
    width: 16px;
    height: 16px;
  }
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
}

.xdated {
  input {
    border: none !important;
    padding: 0px !important;
  }
}

.ant-picker-calendar {
  .ant-picker-panel {
    margin-top: 10px;
  }
  .ant-picker-calendar-date-content {
    height: 0px !important;
  }
  .ant-picker-calendar-date-value {
    text-align: center !important;
    line-height: 32px !important;
  }
  .ant-picker-calendar-date {
    border-top: none !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    margin: 0 auto !important;
    border-radius: 100px !important;
  }
  .ant-picker-cell {
    border-color: transparent !important;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
  }
  thead {
    position: relative;
    top: -4px;
    tr {
      th {
        padding: 0 0 4px !important;
        text-align: center !important;
        line-height: 28px !important;
      }
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          box-shadow: none !important;
        }
      }
    }
  }
  .ant-picker-calendar-date-today {
    border: 1px solid #ccc !important;
  }
}

.calendar_selector {
  height: 30px !important;
  .ant-select-selector {
    box-shadow: none !important;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.tabbed_months {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -19px;
  width: calc(100% + 19px);
  overflow: scroll;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-top: 15px;
  .tm_item {
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
    padding: 3px 12px;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
    transition: all 0.3s ease;
    text-transform: capitalize;
    cursor: pointer;
    &.selected {
      background-color: #F6CE2F;
    }
  }
}

.slick-list {
  border-radius: 12px;
  overflow: hidden;
}

.xxslider {
  .slick-list {
    border-radius: 0px;
  }
}

.city_small {
  font-size: 28px;
  opacity: 0.7;
}

@media screen and (min-width: 1920px) {
  .container2 {
    max-width: 1860px !important;
  }
}

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: #f6cf2f !important;
    }
  }
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: #f6cf2f !important;
        background-color: #f6cf2f !important;
      }
    }
  }
}

.xsorts {
  label {
    display: flex;
    margin-bottom: 12px;
  }
}