.options_modal {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .options_ph {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100svh;
    background-color: rgba(0, 0, 0, 0.6);
    // backdrop-filter: blur(4px);
    transition: all 0.3s ease;
    z-index: 999999999;
  }
  .options_body {
    z-index: 9999999999;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 10px;
    width: 490px;
    left: calc(50% - 245px);
    // top: 3vh;
    // min-height: 94vh;
    // min-height: 94svh;
    padding: 20px;
    max-height: 90%;
    &.heighted {
      min-height: unset !important;
      display: block !important;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      // width: 90% !important;
      // left: unset !important;
      .save {
        margin-top: 24px;
        width: 100% !important;
        position: relative !important;
        left: unset !important;
        bottom: unset !important;
      }
    }
    h2 {
      font-size: 34px;
      font-weight: 900;
      font-family: 'Farm';
    }
    .options_body__close {
      height: 36px;
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 24px;
      color: #000;
      cursor: pointer;
      z-index: 9999999999;
      background-color: #ffffff;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
    }
    .save {
      // position: fixed;
      // bottom: 48px;
      // width: 500px;
      // left: calc(50% - 250px);
      text-align: center;
      button {
        width: 80%;
        padding: 12px 24px;
        font-size: 18px;
        font-weight: 500;
        color: rgb(0, 0, 0);
        background-color: #f7ce30;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: all 0.4s ease;
        text-align: center;
        text-decoration: none;
        margin-top: 16px;
        transform: scale(1);
        &:hover {
          opacity: 0.9;
          background-color: #f7ce30;
        }
        &:active {
          transform: scale(0.97);
        }
        &.danger {
          background-color: rgb(208, 13, 13) !important;
          &:hover {
            background-color: rgb(187, 9, 9) !important;
            color: #fff;
          }
        }
      }
    }
  }
}

.options_body__content {
  margin-top: 20px;
  .f_label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 500px) {
  .options_body {
    .save {
      button {
        // width: 60% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .options_body {
    width: 90% !important;
    left: 5% !important;
    .save {
      // position: fixed;
      // bottom: 48px;
      width: 100%;
      // left: 0;
      text-align: center;
    }
  }
}