.ximage {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}

.xcarousel {
  margin-top: 72px;
  border-radius: 14px;
  // overflow: hidden;
}

.not_found {
  padding: 150px 0 100px;
  strong {
    font-size: 42px;
    font-weight: 900;
  }
  h1 {
    font-size: 28px;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 12px;
    outline: none;
    border: none;
    color: rgba(0, 0, 0, 0.9);
    background-color: #f7ce30;
    padding: 18px 62px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    line-height: 1;
    transition: all 0.3s ease;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
      background-color: #f3c511;
    }
  }
}

@media screen and (min-width: 768px) {
  .not_found {
    padding: 200px 0 100px !important;
    text-align: center !important;
    strong {
      font-size: 82px;
    }
    h1 {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 768px) {
  .xcarousel {
    margin-top: -10px;
    margin-bottom: 24px;
  }
  .ximage {
    height: 200px;
  }
  .head {
    strong {
      margin-top: 2px !important;
      font-size: 48px !important;
    }
    h1 {
      font-size: 20px !important;
      font-weight: 700 !important;
      margin-bottom: 22px !important;
      margin-top: 14px !important;
    }
  }
  .features {
    h2 {
      font-size: 45px !important;
      max-width: 340px;
      margin: 0 auto 40px;
    }
    .fcard {
      margin-top: 0px !important;
      margin-bottom: 16px;
      height: unset !important;
      p {
        margin-bottom: 0px !important;
      }
    }
  }
  .partners {
    h2 {
      font-size: 32px !important;
      max-width: 340px;
      margin: 0 auto 40px;
    }
    .plist {
      margin: 0 auto 52px !important;
    }
  }
  .features {
    padding-bottom: 140px !important;
  }
  .categories {
    padding-bottom: 120px !important;
    h2 {
      font-size: 45px !important;
      max-width: 340px;
      margin: 0 auto 40px;
    }
    .cats {
      .cat_item {
        width: 100% !important;
        padding: 12px 0 !important;
        &:nth-last-child(1),
        &:nth-last-child(2) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
        }
        span {
          border-left: none !important;
          padding: 0px !important;
        }
      }
    }
  }
  .cat_preview {
    display: none;
  }
}

.cntrx {
  max-width: 700px;
  text-align: center;
  margin: 56px auto 0;
  font-size: 24px;
}

.head {
  strong {
    margin-top: 62px;
    font-size: 76px;
    font-weight: 900;
    line-height: 0.9;
    letter-spacing: -1px;
    display: block;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    margin-top: 24px;
  }
  h2 {}
  p {
    max-width: 500px;
    margin-bottom: 32px;
  }
}

.action {
  &.full {
    width: 100%;
    text-align: center;
    margin-top: 52px;
  }
  button,
  a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 12px;
    outline: none;
    border: none;
    color: rgba(0, 0, 0, 0.9);
    background-color: #f7ce30;
    padding: 18px 62px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    transition: all 0.3s ease;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
      background-color: #f3c511;
    }
  }
}

.features {
  padding-top: 120px;
  padding-bottom: 140px;
  h2 {
    text-align: center;
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  .fcard {
    border-radius: 20px;
    background-color: #F7F7F9;
    padding: 26px 26px;
    &.fixed {
      height: 210px;
      margin-top: 28px;
    }
    h3 {
      font-weight: 600;
    }
    p {
      font-size: 18px;
    }
  }
}

.categories {
  padding-top: 0px;
  padding-bottom: 120px;
  h2 {
    text-align: center;
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  a {
    width: 170px;
    display: block;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 18px;
    opacity: 0.7;
    transition: all 0.3s ease;
    position: relative;
    margin-top: 40px;
    b {
      position: absolute;
      right: 0;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      opacity: 1;
      &::after {
        opacity: 1;
        bottom: 0px;
      }
    }
    &::after {
      content: " ";
      width: 100%;
      position: absolute;
      height: 1px;
      bottom: -4px;
      opacity: 0;
      left: 0;
      background-color: rgb(0, 0, 0);
      transition: all 0.3s ease;
      animation: menu_active 2s ease infinite;
    }
  }
  .cat_preview {
    width: 100%;
    height: 360px;
    position: relative;
    div {
      width: 100%;
      height: 360px;
      position: absolute;
      top: 0;
      opacity: 0;
      transition: all 0.3s ease;
      border-radius: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .cats {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .cat_item {
      cursor: pointer;
      width: 50%;
      font-size: 18px;
      font-weight: 500;
      padding: 26px 0;
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      span {
        transition: all 0.4s ease;
        opacity: 0.8;
        height: 48.5px;
        display: inline-flex;
        align-items: center;
      }
      b {
        position: absolute;
        top: 27%;
        right: -20px;
        transition: all 0.4s ease;
        opacity: 0;
        font-size: 32px;
      }
      &:nth-child(2n) {
        span {
          border-left: 1px solid rgba(0, 0, 0, 0.08);
          padding: 0 50px 0 30px;
        }
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        border-bottom: none;
      }
      &:hover {
        span {
          opacity: 1;
        }
        b {
          right: 16px;
          opacity: 1;
        }
      }
    }
  }
}

.partners {
  padding-top: 0px;
  padding-bottom: 0px;
  h2 {
    text-align: center;
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 100px;
  }
  .plist {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 72px;
    // max-width: 700px;
    margin: 0 auto 102px;
    &.last {
      margin-bottom: 0px !important;
    }
    a {
      display: flex;
      align-items: center;
      width: fit-content;
      text-align: center;
      margin-right: 42px;
      opacity: 0.8;
      transition: all 0.4s ease;
      transform: scale(1);
      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
      img {
        // width: 160px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .partners {
    padding-bottom: 80px;
    h2 {
      margin-bottom: 52px;
    }
    .plist {
      a {
        margin-right: 24px !important;
        &:nth-child(2) {
          margin-right: 0px !important;
        }
        img {
          height: 28px !important;
        }
      }
    }
  }
}

@keyframes menu_active {
  0% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    width: 80%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}