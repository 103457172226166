.policy {
  padding-top: 140px;
  .wrapper {
    background-color: #fff;
    // padding: 16px 16px;
    border-radius: 10px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 36px;
    font-weight: 900;
  }
  h2 {
    font-size: 20px;
    margin-bottom: 32px;
  }
  h3 {
    margin-top: -24px;
    margin-bottom: 32px;
    font-weight: 700;
  }
  .addr {
    margin-bottom: 32px;
  }
  .points {
    p {
      line-height: 1.3;
      br {
        line-height: 5;
      }
      b {
        margin-bottom: 0px;
        margin-top: 12px;
        display: block;
      }
    }
  }
  a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    position: relative;
    transition: all 0.4s ease;
    &::before {
      content: " ";
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 0px;
      height: 1px;
      background-color: #000;
      transition: all 0.4s ease;
    }
    &:hover {
      color: #000;
      &::before {
        width: 100%;
      }
    }
  }
}

.rules {
  p {
    letter-spacing: 1.2px;
    line-height: 1.6;
    font-size: 18px;
  }
  h6 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 1.6;
  }
}

@media screen and (min-width: 1100px) {
  .rules {
    max-width: 960px;
  }
}

@media screen and (max-width: 960px) {
  .policy {
    padding-top: 130px !important;
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
}